import React,{useEffect, useState} from "react"
import { Col, Row } from "react-bootstrap"
import { CustomModal } from "../CustomModal"
import { assets } from "../../utils"
import { CustomButton } from "../CustomButton"
import AsyncSelect from 'react-select/async';
import { InputField } from "../../components/InputField"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as Yup from "yup"
import { useBrowseProductsContext } from "../../contexts/BrowseProductsContext"
import { API } from "../../api"
import Select from 'react-select';

export const ProductListingPopUp = ({
  show,
  onHide,
  title,
  size = "lg",
  selectedProductData,
  setSelectedProductData,
}) => {
  const {
    isLoading,
    browseProducts,
    getBrowseProducts,
    brands,
    productTypes,
    filterParams,
    setFilterParams,
    setBrands,
    setProductTypes,
    product,
    getBrowseProduct,
    addProductToMyStore,
    removeProductFromMyStore,
    suggestedActive,
    setSuggestedActive
  } = useBrowseProductsContext()
  const { next, results } = browseProducts
  const [products, setProducts] = useState([])
  const [defaultOptions, setDefaultOptions] = useState([])
  const [editMode, setEditMode] = useState(false)
  const [quantity, setQuantity] = useState(1)
  const [selectedProduct, setSelectedProduct] = useState(null)
  const [variant, setVariant] = useState([])
  const [selectedVariant, setSelectedVariant] = useState(null)
  const formSchema = Yup.object().shape({
    name: Yup.string()
      .required("Name is required")
      .min(3, "Name must be at 3 char long")
      .trim()
      .matches(/^[a-zA-Z ]+$/, "Invalid name"),
    address_line1: Yup.string().required("Address line 1 is required"),
    // address_line2: Yup.string().required("Address line 2 is required"),
    city: Yup.string().required("City is required"),
    zipcode: Yup.string().required("Zip code is required"),
    state: Yup.string().required("State is required"),
    country: Yup.string().required("Country is required")
  })
  const formOptions = { resolver: yupResolver(formSchema) }
  const { control, trigger, setValue, handleSubmit } = useForm(formOptions)


  useEffect(() => {
    getBrowseProducts({ params: filterParams })
  }  , [])
  useEffect(() => {
    
    let results_new = []
    let fullResults = []
    results && results.map((item) => {
      if(item.in_stock){
        let available = false
        item.product_catalog_variants_list && item.product_catalog_variants_list.map((item) => {
          if(item.in_stock){
            available = true
          }
        })
        if(available){
          fullResults.push(item)
          results_new.push({value: item.id, label: item.title})
        }
      }
      
      
    })
    setProducts(fullResults);
    
    setDefaultOptions(results_new)
  }, [results])
  const loadMoreProducts = params => {
    const {
      page,
      price_min,
      price_max,
      product_type,
      vendor,
      search,
      suggested
    } = params
    console.log("params", params);
    const config = {
      params: {
        page,
        search,
        price_min,
        price_max,
        in_stock: true,
        synced_products: params.syncedProducts,
        vendor: vendor?.join(","),
        product_type: product_type?.join(","),
        suggested
      }
    }
    getBrowseProducts(config)
  }
  useEffect(() => {
    loadMoreProducts({ ...filterParams, page: 1 })
  }, [filterParams])
  

  const handleAddProduct = async () => {
    if (selectedProduct == null) {
      alert("Please select a product");
    } else {
      let findData = products.find(item => item.id == selectedProduct.value);
      let findVariant = findData.product_catalog_variants_list.find(item => item.id == selectedVariant.value);
  
      let productDetails = {
        id: findData.id,
        name: `${findData.title} - ${findVariant.title}`,
        product_id: findData.id,
        variant_id: findVariant.id,
        variant_name: findVariant.title,
        price: parseFloat(findVariant.price) || 0, // Ensure numeric values
        cost_price: parseFloat(findVariant.cost) || 0, // Ensure numeric values
        quantity: parseInt(quantity) || 0, // Ensure numeric values
        price_total: (parseFloat(findVariant.price) || 0) * (parseInt(quantity) || 0),
        cost_price_total: (parseFloat(findVariant.cost) || 0) * (parseInt(quantity) || 0),
      };
  
      // Remove any existing "Sub Total" row
      const filteredProductData = selectedProductData.filter(item => item.name !== "Sub Total");
  
      // Add the new product
      const updatedProductData = [...filteredProductData, productDetails];
  
      // Recalculate totals
      const totals = updatedProductData.reduce(
        (acc, item) => {
          acc.price += parseFloat(item.price) || 0;
          acc.cost_price += parseFloat(item.cost_price) || 0;
          acc.quantity += parseInt(item.quantity) || 0;
          acc.price_total += parseFloat(item.price_total) || 0;
          acc.cost_price_total += parseFloat(item.cost_price_total) || 0;
          return acc;
        },
        { price: 0, cost_price: 0, quantity: 0, price_total: 0, cost_price_total: 0 }
      );
  
      // Create the last row
      const lastRow = {
        name: "Sub Total",
        price: totals.price,
        cost_price: totals.cost_price,
        quantity: totals.quantity,
        price_total: totals.price_total,
        cost_price_total: totals.cost_price_total,
      };
  
      // Update state with the new data and last row
      setSelectedProductData([...updatedProductData, lastRow]);
      setQuantity(1);
      setSelectedProduct(null);
      setSelectedVariant(null);
      onHide();
    }
  };
  
  
  
  return (
    <CustomModal
      size={size}
      showHeader={true}
      headerTitle={title}
      show={show}
      onHide={onHide}
    >
      <Row>
        <Col xs="12" className="mb-2">
          {false && (
            <h6 className="title">
              Browse Products
            </h6>
          )}
        </Col>
        <Col xs="12" className="mb-3">
          <label class="form-label">
            Product
          </label>
          <AsyncSelect
            cacheOptions
            defaultOptions={defaultOptions}
            placeholder="Search Products"
            onChange={(selectedOption) => {
              let findData = products.find(item => item.id == selectedOption.value)
              let variants = []
              
              findData.product_catalog_variants_list && findData.product_catalog_variants_list.map((item) => {
                if(item.in_stock){
                  variants.push({value: item.id, label: item.title})
                }
                
              })
              setVariant(variants)
              setSelectedProduct(selectedOption)
              
            }}
            loadOptions={async (inputValue, callback) => {
              try {
                // Fetch filtered products based on input value
                const config = {
                  params: {
                    ...filterParams,
                    search: inputValue, // Pass the search query
                    page: 1, // Reset to the first page for a new search
                  },
                };
          
                await API.getBrowseProducts(config)
                .then(response => {
                  const { count, next, results } = response.data.data
                  
                  let results_new = []
                  let fullResults = []
                  results && results.map((item) => {
                    if(item.in_stock){
                      let available = false
                      item.product_catalog_variants_list && item.product_catalog_variants_list.map((item) => {
                        if(item.in_stock){
                          available = true
                        }
                      })
                      if(available){
                        results_new.push({value: item.id, label: item.title})
                        fullResults.push(item)
                      }
                    }
                    
                    
                  })
                  setProducts(fullResults)
                  callback(results_new);
                  
                })
                .catch(error => {
                  console.log(error)
                  callback([]);
                })
                .finally(() => {

                })
                  
                
              } catch (error) {
                console.error("Error loading options:", error);
                callback([]);
              }
            }}
          />
        </Col>
        {selectedProduct != null ?  <Col xs="12" className="mb-3">
          <label class="form-label">
            Variant
          </label>
          <Select
            className="basic-single"
            classNamePrefix="select"
            defaultValue={selectedVariant}
            name="variant"
            options={variant}
            placeholder="Select Variant"
            onChange={(selectedOption) => {
              setSelectedVariant(selectedOption)
            }}
          />
        </Col> : null}
       {selectedProduct != null ?  <Col xs="12" className="mb-3">
            <InputField
              label={'Quantity'}
              name={'quantity'}
              type={'number'}
              value={quantity}
              onChange={e => {
                setQuantity(e.target.value)
              }}
              placeholder={'Quantity'}
              disabled={editMode}
              control={control}
              trigger={trigger}
              rules={{ required: "Quantity is required!" }}
            />
        </Col> : null}
        <Col xs="12" className="mb-4">
            <CustomButton
              label="Add Product"
              variant="outline-primary"
              backgroundColor="white"
              styles={{marginLeft:'0px'}}
              disabled={editMode}
              onClick={() =>
              {
                handleAddProduct()
              }
              }
            />
        </Col>
      </Row>
    </CustomModal>
  )
}
