import { Container, Row, Col } from "react-bootstrap"
import { assets } from "../../utils"
import { CustomAgGridTable } from "../../components/CustomAgGridTable"
import { column } from "./columns"
import { useNavigate, useParams } from "react-router-dom"
import { PaymentCard } from "./paymentCard"
import { ShippingCard } from "./shippingCard"
import { NoteCard } from "./noteCard"
import { useEffect, useState } from "react"
import { useOrdersContext } from "../../contexts/OrdersContext"
import { CustomSpinner } from "../../components/CustomSpinner"
import { useAuthContext } from "../../contexts/AuthContext"
import { InfoPopup } from "../../components/InfoPopup"
import { CreditCard } from "../../components/CreditCard"
import { ShippingDetails } from "./shippingdetails"
import { CustomButton } from "../../components/CustomButton"
import { ProductListingPopUp } from "../../components/ProductListingPopUp"
import { CustomCard } from "../../components/CustomCard"
import { InputField } from "../../components/InputField"
import { set, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as Yup from "yup"
import Select from 'react-select';
import { API } from "../../api"
import "./ordersdetails.scss"

export const NewOrders = () => {
  const nav = useNavigate()
  const params = useParams()
  const { notifySuccess } = useAuthContext()
  const {
    isLoading,
    order,
    getOrder,
    addOrderNote,
    updateOrderNote,
    raiseOrderRequest,
    deleteOrderNote,
    rates
  } = useOrdersContext()

  const [name, setName] = useState("")
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [email, setEmail] = useState("")
  const [phone, setPhone] = useState("")
  const [address_line1, setAddress_line1] = useState("")
  const [address_line2, setAddress_line2] = useState("")
  const [city, setCity] = useState("")
  const [zipcode, setZipcode] = useState("")
  const [state, setState] = useState("")
  const [country, setCountry] = useState("United States")
  const [stateList , setStateList] = useState([
    { value: 'AK', label: 'Alaska'},
    { value: 'TX', label: 'Texas'},
    { value: 'AL', label: 'Alabama'},
    { value: 'AR', label: 'Arkansas'},
    { value: 'AZ', label: 'Arizona'},
    { value: 'CA', label: 'California'},
    { value: 'CO', label: 'Colorado'},
    { value: 'CT', label: 'Connecticut'},
    { value: 'DC', label: 'District of Columbia'},
    { value: 'DE', label: 'Delaware'},
    { value: 'FL', label: 'Florida'},
    { value: 'GA', label: 'Georgia'},
    { value: 'HI', label: 'Hawaii'},
    { value: 'IA', label: 'Iowa'},
    { value: 'ID', label: 'Idaho'},
    { value: 'IL', label: 'Illinois'},
    { value: 'IN', label: 'Indiana'},
    { value: 'KS', label: 'Kansas'},
    { value: 'KY', label: 'Kentucky'},
    { value: 'LA', label: 'Louisiana'},
    { value: 'MA', label: 'Massachusetts'},
    { value: 'MD', label: 'Maryland'},
    { value: 'ME', label: 'Maine'},
    { value: 'MI', label: 'Michigan'},
    { value: 'MN', label: 'Minnesota'},
    { value: 'MO', label: 'Missouri'},
    { value: 'MS', label: 'Mississippi'},
    { value: 'MT', label: 'Montana'},
    { value: 'NC', label: 'NorthCarolina'},
    { value: 'ND', label: 'NorthDakota'},
    { value: 'NE', label: 'Nebraska'},
    { value: 'NH', label: 'NewHampshire'},
    { value: 'NJ', label: 'NewJersey'},
    { value: 'NM', label: 'NewMexico'},
    { value: 'NV', label: 'Nevada'},
    { value: 'NY', label: 'NewYork'},
    { value: 'OH', label: 'Ohio'},
    { value: 'OK', label: 'Oklahoma'},
    { value: 'OR', label: 'Oregon'},
    { value: 'PA', label: 'Pennsylvania'},
    { value: 'RI', label: 'RhodeIsland'},
    { value: 'SC', label: 'SouthCarolina'},
    { value: 'SD', label: 'SouthDakota'},
    { value: 'TN', label: 'Tennessee'},
    { value: 'TX', label: 'Texas'},
    { value: 'UT', label: 'Utah'},
    { value: 'VA', label: 'Virginia'},
    { value: 'VT', label: 'Vermont'},
    { value: 'WA', label: 'Washington'},
    { value: 'WI', label: 'Wisconsin'},
    { value: 'WV', label: 'WestVirginia'},
    { value: 'WY', label: 'Wyoming'}
  ])

  const [show, setShow] = useState(false)
  const onHide = () => setShow(false)
  const [selectedProduct, setSelectedProduct] = useState([])
  const [editMode, setEditMode] = useState(false)

  const formSchema = Yup.object().shape({
    firstName: Yup.string()
      .required("First Name is required")
      .min(3, "First Name must be at 3 char long")
      .trim()
      .matches(/^[a-zA-Z ]+$/, "Invalid First Name"),
    lastName: Yup.string()
      .required("Last Name is required")
      .min(3, "Last Name must be at 3 char long")
      .trim()
      .matches(/^[a-zA-Z ]+$/, "Invalid Last Name"),
    email: Yup.string().email("Invalid email").required("Email is required"),
    address_line1: Yup.string().required("Address line 1 is required"),
    // address_line2: Yup.string().required("Address line 2 is required"),
    city: Yup.string().required("City is required"),
    zipcode: Yup.string().required("Zip code is required")
  })
  const formOptions = { resolver: yupResolver(formSchema) }
  const { control, trigger, setValue, handleSubmit } = useForm(formOptions)

  const onSubmit = vals => {
    if(selectedProduct.length === 0){
      alert("Please add at least one product to create order.")
      return
    }
    const payload = {
      name: firstName + " " + lastName,
      
      total_selling_price :selectedProduct
      .filter((product) => product.name === "Sub Total") // Filter products with name "Sub Total"
      .reduce((acc, { price_total }) => acc + price_total, 0).toFixed(2),
      total_cost_price :selectedProduct
      .filter((product) => product.name === "Sub Total") // Filter products with name "Sub Total"
      .reduce((acc, { cost_price_total }) => acc + cost_price_total, 0).toFixed(2),
      shipping_address :{
        name:firstName + " " + lastName,
        first_name:firstName,
        last_name:lastName,
        email,
        address1:address_line1,
        address2:address_line2,
        city,
        zip:zipcode,
        country_code: "US",
        state: state?.label,
        province_code: state?.value,
        country
      },
      order_line_items : selectedProduct
      .slice(0, -1) // Removes the last item
      .map(({ id, price, cost_price,quantity,variant_id }) => ({ product_id: id, price, cost_price, quantity,variant_id }))
    }
    setEditMode(true)
    API.createManualOrder(payload)
        .then(response => {
          notifySuccess("Order will be added to your store after sometime.")
          nav("/orders/"+response.data.data.order_id)
        })
        .catch(error => {

        })
        .finally(() => {
          setEditMode(false)
        })
  }


  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Container className="orders-details-container" fluid>
        <Row className="p-1">
          <Col
            xs="9"
            md="9"
            className="d-flex align-items-center"
            onClick={() => nav("/orders")}
            style={{ cursor: "pointer" }}
          >
            <img src={assets.BackArrowIcon} />
            <h5 className="page-title mb-0 ms-1">{`New Order`}</h5>
          </Col>
          <Col xs="3" md="3" className="d-flex align-items-end justify-content-end">
              <CustomButton
                label="Add Product"
                variant="outline-primary"
                backgroundColor="white"
                styles={{marginRight:'10px',display:'block'}}
                disabled={isLoading}
                type="button"
                onClick={() =>
                {
                  setShow(true)
                }
                }
              />
              <CustomButton
                      label="Save Product"
                      variant="primary"
                      disabled={isLoading}
                      styles={{marginLeft:'0px',width:'130px'}}
                      
                      type="submit"
                      
                    />
          </Col>
        </Row>
        <Row className="p-1 mb-3">
          <CustomAgGridTable
            rowData={selectedProduct}
            columnDefs={column}
            pagination={false}
            onRowClicked={({ data: { original_product } }) => {
              alert(original_product)
              if (original_product) {
                nav(`/browse-products/${original_product}`)
              }
            }}
          />
        </Row>
          <ProductListingPopUp
            show={show}
            onHide={onHide}
            title="Add Product"
            size="md"
            selectedProductData={selectedProduct}
            setSelectedProductData={setSelectedProduct}
          />
          <CustomCard title="Shipping Details" className="shipping-info">
              <Row>
                <Col xs="3" className="mb-1">
                    <InputField
                      label={'Customer First Name'}
                      name={'firstName'}
                      type={'text'}
                      value={firstName}
                      onChange={e => {
                        setFirstName(e.target.value)
                        setValue('firstName', e.target.value)
                      }}
                      placeholder={'Customer First Name'}
                      disabled={editMode}
                      control={control}
                      trigger={trigger}
                      rules={{ required: "Customer First Name is required!" }}
                    />
                </Col>
                <Col xs="3" className="mb-1">
                    <InputField
                      label={'Customer Last Name'}
                      name={'lastName'}
                      type={'text'}
                      value={lastName}
                      onChange={e => {
                        setLastName(e.target.value)
                        setValue('lastName', e.target.value)
                      }}
                      placeholder={'Customer Last Name'}
                      disabled={editMode}
                      control={control}
                      trigger={trigger}
                      rules={{ required: "Customer Last Name is required!" }}
                    />
                </Col>
                <Col xs="3" className="mb-1">
                    <InputField
                      label={'Customer Email'}
                      name={'email'}
                      type={'text'}
                      value={email}
                      onChange={e => {
                        setEmail(e.target.value)
                        setValue('email', e.target.value)
                      }}
                      placeholder={'Customer Email'}
                      disabled={editMode}
                      control={control}
                      trigger={trigger}
                      rules={{ required: "Customer Email is required!" }}
                    />
                </Col>
                <Col xs="3" className="mb-1">
                    <InputField
                      label={'Customer Phone'}
                      name={'phone'}
                      type={'text'}
                      value={phone}
                      onChange={e => {
                        setPhone(e.target.value)
                        setValue('phone', e.target.value)
                      }}
                      placeholder={'Customer Phone'}
                      disabled={editMode}
                      control={control}
                      trigger={trigger}
                      rules={{ required: "Customer Phone is required!" }}
                    />
                </Col>
                
                
              </Row>
              <Row>
                <Col xs="6" className="mb-1">
                    <InputField
                      label={'Address Line 1'}
                      name={'address_line1'}
                      type={'text'}
                      value={address_line1}
                      onChange={e => {
                        setAddress_line1(e.target.value)
                        setValue('address_line1', e.target.value)
                      }}
                      placeholder={'Address Line 1'}
                      disabled={editMode}
                      control={control}
                      trigger={trigger}
                      rules={{ required: "Address Line 1 is required!" }}
                    />
                </Col>
                <Col xs="6" className="mb-1">
                    <InputField
                      label={'Address Line 2'}
                      name={'address_line2'}
                      type={'text'}
                      value={address_line2}
                      onChange={e => {
                        setAddress_line2(e.target.value)
                        setValue('address_line2', e.target.value)
                      }}
                      placeholder={'Address Line 2'}
                      disabled={editMode}
                      control={control}
                      trigger={trigger}
                      rules={{ required: "Address Line 2 is required!" }}
                    />
                </Col>
              </Row>
              <Row>
                <Col xs="3" className="mb-3">
                    <InputField
                      label={'City'}
                      name={'city'}
                      type={'text'}
                      value={city}
                      onChange={e => {
                        setCity(e.target.value)
                        setValue('city', e.target.value)
                      }}
                      placeholder={'City'}
                      disabled={editMode}
                      control={control}
                      trigger={trigger}
                      rules={{ required: "City is required!" }}
                    />
                </Col>
                <Col xs="3" className="mb-3">
                    <InputField
                      label={'Zip'}
                      name={'zipcode'}
                      type={'number'}
                      value={zipcode}
                      onChange={e => {
                        setZipcode(e.target.value)
                        setValue('zipcode', e.target.value)
                      }}
                      placeholder={'Zip'}
                      disabled={editMode}
                      control={control}
                      trigger={trigger}
                      rules={{ required: "Zip is required!" }}
                      maxLength={5}
                    />
                </Col>
                <Col xs="3" className="mb-3">
                  <label class="form-label">
                    State
                  </label>
                  <Select
                    className="basic-single"
                    classNamePrefix="select"
                    defaultValue={state}
                    name="variant"
                    options={stateList}
                    placeholder="Select State"
                    onChange={(selectedOption) => {
                      setState(selectedOption)
                      
                    }}
                  />
                </Col>
                <Col xs="3" className="mb-3">
                    <InputField
                      label={'Country'}
                      name={'country'}
                      type={'text'}
                      value={country}
                      onChange={e => {
                        setCountry(e.target.value)
                        setValue('country', e.target.value)
                      }}
                      placeholder={'Country'}
                      disabled={true}
                      control={control}
                      trigger={trigger}
                      rules={{ required: "Country is required!" }}
                    />
                </Col>
              </Row>
          </CustomCard>
        <CustomSpinner loading={isLoading} />
      </Container>
    </form>
  )
}
