import { Link } from "react-router-dom"

export const column = [
  {
    field: "name",
    headerName: "Product",
    flex: 1,
    minWidth: 280,
    cellRenderer: ({ value }) => (
      <Link className={value} style={{ color: "#27963C" }}>
        {value}
      </Link>
    )
  },
  {
    field: "quantity",
    headerName: "Quantity",
    flex: 1,
    minWidth: 160
  },
  {
    field: "price",
    headerName: "Price Each",
    flex: 1,
    minWidth: 160,
    cellRenderer: ({ value }) => {
      if (value) return <span>{`$ ${Number(value).toFixed(2)}`}</span>
    }
  },
  {
    field: "price_total",
    headerName: "Price Total",
    flex: 1,
    minWidth: 160,
    cellRenderer: ({ value }) => {
      if (value) return <span>{`$ ${Number(value).toFixed(2)}`}</span>
    }
  },
  {
    field: "cost_price",
    headerName: "Cost Each",
    flex: 1,
    minWidth: 160,
    cellRenderer: ({ value }) => {
      if (value) return <span>{`$ ${Number(value).toFixed(2)}`}</span>
    }
  },
  {
    field: "cost_price_total",
    headerName: "Cost Total",
    flex: 1,
    minWidth: 160,
    cellRenderer: ({ value }) => {
      if (value) return <span>{`$ ${Number(value).toFixed(2)}`}</span>
    }
  },
  {
    field: "fulfillment_status",
    headerName: "Status",
    flex: 1,
    minWidth: 160,
    cellRenderer: ({ value }) => <span className={value}>{value}</span>
  },
  {
    field: "",
    headerName: "Tracking Number",
    flex: 1,
    minWidth: 200,
    cellRenderer: ({ data }) => {
      return (
        
        <span style={{cursor:'pointer'}} ref={(ref) => {
          if (!ref) return;
  
          ref.onclick = (e) => {
            
            e.stopPropagation(); // this works
            window.open(`${data?.tracking_information?.tracking_url}`, "_blank")
          };
        }} onPress={
          (e) => {
            e.preventDefault()
            window.open(`${data?.tracking_information?.tracking_url}`, "_blank")
          }
        } className={`${data?.tracking_information?.tracking_number} fulfilled`}>
          {data?.tracking_information?.tracking_number}
        </span>
       
      )
    }
  }
]
